import React from 'react';
import "./Packages.css"
import standard from "../../assets/standard.jpg";
import pro from "../../assets/pro.jpg"
import enterprise from "../../assets/enterprise.jpg"
import PackageContainer from './PackageContainer';
import video_ from "../../assets/packages2.mp4"

const Packages = () => {

  const handleSubscribeButtonClick = () => {
    window.location.replace("https://app.queryloop-ai.com/auth/signup");
  };

  return (
    <div className='pricing-page' data-aos="fade-left">

      <video autoPlay loop muted playsInline className="package-video">
        <source src={video_} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className='pricing-heading-container'>
        <h1 className="heading"  >Our Pricing Plans</h1>
        <p className="para" >Queryloop allows you to automatically build the best version of your RAG bot.</p>
        <br />
        <PackageContainer />
        <div className='pricepackage-info'>
          <p>Please note that the costs for the LLM API and Vector Database API are exclusive and not included in the base subscription fee</p>
        </div>
      </div>

    </div>)
};

export default Packages;



{/* <div>
          <p className="text-blk text-head">
            Our Pricing Plans
          </p>
          <p className="text-blk text-subhead">
          <span>
            Queryloop allows you to automatically build the best version of your RAG bot.
              
            </span>
          </p>
          </div> */}

{/* ************************* Starter ************************ */ }
{/* <div className='package-container'>
      <div className='package_'>
        <div className='package_header'>
          <img src={standard} alt='starter' />
          <h2>Starter</h2>
        </div>

        <div className='package_price'>
          <div className='package_price_info'>
            <p className='priceStyles'>USD 400</p>
            <div className='per-month'>
              <p>per</p>
              <p>month</p>
            </div>
          </div>
          <button onClick={handleSubscribeButtonClick}>Subscribe</button>
        </div>

        <div className='package_includes'>
          <p className='this-inlcudes'>This includes:</p>
          <p > <span className="checkmark">✔</span> Retrieval optimization including the identification of the right chunking strategy, retrieval/reranking, embedding model, and LLM.</p>
          <p ><span className="checkmark">✔</span> Prompt optimization to automatically give the right instruction to the LLM.</p>
          <p ><span className="checkmark">✔</span> Metadata filtering to retrieve only the documents you want.</p>
          <p ><span className="checkmark">✔</span> Problem statement information extraction to land on the best parameters quickly.</p>
        </div>
      </div>

    </div> */}

{/* ************************* Pro ************************ */ }
{/* <div className='package-container'>

          <div className='package_'
            style={{
              border: `2px solid rgba(${255 * borderIntensity}, ${255 * borderIntensity}, ${255 * borderIntensity}, ${borderIntensity})`, // Dynamically adjust border color intensity
            }}
          >
            <div className='package_header'>
              <h2>Pro</h2>
              <img src={pro} alt='pro' />
            </div>

            <div className='package_price'>
              <div className='package_price_info'>
                <p className='priceStyles'>$ 700</p>
                <div className='per-month'>
                  <p>/</p>
                  <p>month</p>
                </div>
              </div>
              <button onClick={handleSubscribeButtonClick}>Subscribe</button>
            </div>
            <br />

            <div className='package_includes'>
              <p className='this-inlcudes'>This includes:</p>
              <p > <span className="checkmark">✔</span> Retrieval optimization including the identification of the right chunking strategy, retrieval/reranking, embedding model, and LLM.</p>
              <p ><span className="checkmark">✔</span> Prompt optimization to automatically give the right instruction to the LLM.</p>
              <p ><span className="checkmark">✔</span> Metadata filtering to retrieve only the documents you want.</p>
              <p ><span className="checkmark">✔</span> Problem statement information extraction to land on the best parameters quickly.</p>
              <p ><span className="checkmark">✔</span> Access to embedding optimization</p>
              <p ><span className="checkmark">✔</span> Access to dual fine-tuning of retrieval, and LLM (coming soon)</p>
              <p ><span className="checkmark">✔</span> Access to Beta Features</p>
            </div>



          </div>

        </div> */}

{/* ************************* Enterprise ************************ */ }
{/* <div className='package-container'>
          <div className='package_'>
            <div className='package_header'>
              <h2>Enterprise</h2>
              <img src={enterprise} alt='enterprise' />
            </div>

            <div className='package_price'>
              <div className='package_price_info'>
                <p className='priceStyles'>Contact us for pricing</p>

              </div>
              <button className='button' onClick={handleSubscribeButtonClick}>Contact us</button>
            </div>
            <br />
            <div className='package_includes'>
              <p className='this-inlcudes'>This includes:</p>
              <p > <span className="checkmark">✔</span> <b >Everything in Pro Package plus the following:</b></p>
              <p ><span className="checkmark">✔</span> Extensive Custom Support by top LLM experts</p>
            </div>


          </div>

        </div>

        */}