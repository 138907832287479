import React, { useEffect } from 'react';
import discordIcon from "../../assets/discord.png";
import slackIcon from "../../assets/slack.png";
import whatsapp from "../../assets/whatsapp.png";
import telegramIcon from "../../assets/telegram.png";
import calendlyIcon from "../../assets/Calendly.png";
import "./Integrations.css";

const Integrations = () => {

    const integrationPlatforms = [
        { name: "Discord", icon: discordIcon },
        { name: "Slack", icon: slackIcon },
        { name: "WhatsApp", icon: whatsapp },
        { name: "Telegram", icon: telegramIcon },
        { name: "Calendly", icon: calendlyIcon },
    ];

    return (
        <div className='integrations-section'>
            <hr className='full-width-line' />

            <h2 style={{ color: "#E5EFFF" }}>Seamlessly Integrate Queryloop Into Your Workflow</h2>

            <p className="integrations-description">
                Easily connect your application with popular platforms to streamline your workflow and enhance user experience.
            </p>
            <div className='integrations-viewport'>
                <div className='integrations-icons'>
                    {integrationPlatforms.map((platform, index) => (
                        <div key={index} className="integration-platform" data-aos="fade-up">
                            <img className={platform.name === "Calendly" ? "calendly-img" : ""} src={platform.icon} alt={platform.name} />
                            <p>{platform.name}</p>
                        </div>
                    ))}
                </div>
            </div>
            <hr className='full-width-line' />
        </div>
    );
}

export default Integrations;
