import React from 'react'
import "./navigation.css"
import CTA from '../CTA/CTA'
import { useNavigate } from 'react-router-dom'

const Navigation = () => {
  const navigate = useNavigate();
  return (
    <div className='navigation'>
      <div className='logo-section' onClick={() => navigate("/")} >
        <h1>Queryloop</h1>
        <div className='logo'></div>
      </div>
      <CTA />
    </div>
  )
}

export default Navigation