import React from "react";
import "./cta.css";

const CTA = () => {
  const [show, setShow] = React.useState(false);
  const handleShow = () => {
    setShow(!show);
  };

  return (
    <div className="cta" id="cta">
      <div className='docs-nav'>
        <button onClick={() => window.location.replace("http://docs.queryloop-ai.com/docs/category/getting-started-with-queryloop")}>Documentation</button>
      </div>
      <div className="nav-links">
        <a href="#package">Pricing</a>
        <a href="/blog">Blog</a>
        <a href="/consultancy"  >Consultancy</a>

      </div>
      <div className="btn login">
        <button onClick={() => window.location.replace("https://app.queryloop-ai.com/auth/login")}>Login</button>
      </div>

      <div className="btn signup">
        <button onClick={() => window.location.replace("https://app.queryloop-ai.com/add/organization")}>Sign up for free</button>
      </div>
      <div className="ham">
        <button onClick={handleShow}>
          {show ? "X" : <span>&#9776;</span>}
        </button>
      </div>
      <div
        className="responsive-btn-container"
        style={{ display: show ? "flex" : "none", transition: "0.4s ease-in" }}
      >
        <div className="login-resp">
          <button onClick={() => window.location.replace("https://app.queryloop-ai.com/auth/login")}>Login</button>
        </div>

        <div className="signup-resp">
          <button onClick={() => window.location.replace("https://app.queryloop-ai.com/auth/add/organization")}>Sign up for free</button>
        </div>
        <div className='docs-nav-resp'>
          <button onClick={() => window.location.replace("http://docs.queryloop-ai.com/docs/category/getting-started-with-queryloop")}>Documentation</button>
        </div>
        <div className="nav-links-resp">
          <a href="#package">Pricing</a>
          <a href="#cta">Blog</a>
          <button onClick={() => window.location.replace("https://cognify-ai.com/")} >Consultancy</button>

        </div>
      </div>
    </div>
  );
};

export default CTA;
