import React from 'react';
import './ConsultancyPage.css';
import CounsultanyBg from "../../assets/consultancybg1.svg"
import ConsultancyTeams from "../../assets/consultancyteams.gif"
const ConsultancyPage = () => {

    const openEmailClient = () => {
        const recipient = 'info@queryloop-ai.com';
        const subject = 'Regarding your services';
        const body = 'Dear QueryLoop AI team,\n\nI am interested in learning more about your services.\n\nRegards, [Your Name]';

        const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

        // Open the default email client with the pre-filled recipient, subject, and body
        window.open(mailtoLink);
    };

    return (
        <div data-aos={"fade-up"} data-aos-delay={"150"} className="consultancy-page">
            <section className="section section_hero">
                <img src={CounsultanyBg} alt='consultancy' className="background-image" />
                <h1 className="heading text-gradient">Building Next Generation Large Language Model Apps</h1>
                <p className="subheading">EngineRay is a team of experienced professionals committed to revolutionizing AI-powered conversational agents using LLMs.</p>
            </section>

            <section data-aos={"fade-up"} data-aos-delay={"150"} className="section-teams">
                <div>
                    <h2 className="heading">Our Team</h2>
                    <p className="subheading">Our team consists of PhDs in Machine/Deep Learning from Georgia Tech, Delft University of Technology with real-world experience in building LLM applications. We have working experience in industrial powerhouses such as Microsoft, Salesforce, and Amazon.</p>
                </div>
                <div className='teams-img'>
                    {/* <img src={ConsultancyTeams} alt='Teams' height={"400px"} /> */}
                </div>
            </section>

            <section data-aos={"fade-up"} data-aos-delay={"150"} className="section section-why">
                <h2 className="heading text-gradient">Why Us</h2>
                <p className="subheading">We have the skills and knowledge required to develop, implement, and manage LLM applications in various domains. We are equipped to create AI-powered conversational agents using LLMs, leveraging transformers, prompt engineering, in-context learning, fine-tuning, indexes, vector stores, retrieval, conversational memory, Langchain chains, agents, and tools for callbacks, tracing, and debugging.</p>
            </section>

            <section data-aos={"fade-up"} data-aos-delay={"150"} className="section">
                <h2 className="heading">Contact Us</h2>
                <p className="subheading">Get in touch with us to learn more about our services and how we can help you build cutting-edge LLM applications.</p>
                <button className="btn-email" onClick={openEmailClient}>Email Us</button>
            </section>

        </div>
    )
}

export default ConsultancyPage;
