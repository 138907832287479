import React from 'react'
import './card.css'

const Card = ({ heading, subtitle, image, anime, index, detailsImg1, detailsImg2, detailsImg3 }) => {
    const get_animation_effect = (i) => {
        const animations = ["fade-up", "zoom-out-down", "fade-left", "flip-right"]
        return animations[0];
    }

    return (

        <div className='card-container' data-aos-easing="ease-in-sine" data-aos-anchor-placement="top-center" data-aos={get_animation_effect(index)} data-aos-delay={"100"} data-aos-duration={"500"}>
            <div className={`card ${index % 2 === 0 ? "row" : "row-reverse"}`}>

                <div className={`card_media-heading`}>
                    <div className='card-media'>
                        <img src={image} alt='data' />

                    </div>
                    <div className='card-heading'>
                        <p className={index % 2 === 0 ? "" : "text-shadow"}>{heading}</p>
                    </div>
                    <div className='card-anime'>
                        <img className={`${index % 2 === 0 ? "image-margin" : ""}`} src={anime} alt='data' />
                    </div>
                </div>

                <div className='card-subtitles'>

                    {subtitle.map((desc, index) => (
                        <div key={index} className='subtitle'>
                            <div className='image-container'>
                                {index === 0 && <img src={detailsImg1} alt='data' />}
                                {index === 1 && <img src={detailsImg2} alt='data' />}
                                {index === 2 && <img src={detailsImg3} alt='data' />}
                            </div>
                            <p>{desc}</p>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    )
}

export default Card