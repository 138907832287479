import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import "./Post.css"
import { posts_ } from '../Blog';


const Post = () => {
    const [newPosts, setNewPosts] = useState([]);
    const { id } = useParams();
    const [location, setLocation] = useState({ state: posts_[posts_.findIndex(p => p.id === id)] });

    useEffect(() => {
        if (localStorage.getItem("posts_title") && newPosts.length === 0) {
            setNewPosts(JSON.parse(localStorage.getItem("posts_title")));
        }

        if (!location.state) {
            console.log(location.state)
            setLocation({ state: posts_[posts_.findIndex(p => p.id === id)] })
        }

    }, [id, location.state, newPosts.length]);


    return (
        <div className='open-blog-post'>
            <header>
                <h2 className='text-gradient heading'>{location.state.title}</h2>
                <div className="post-meta">
                    <span>By {location.state.author}</span>
                    <span>on {location.state.date}</span>
                </div>
            </header>
            <div className="post-excerpt">
                <p>{location.state.excerpt}</p>
            </div>
            <div className="post-content">
                {location.state.parts?.map((part) => (
                    <div key={part.heading || Math.random()}>
                        {part.heading && <h3>{part.heading}</h3>}
                        {part.description && <p>{part.description}</p>}
                        {part.space && <p>{part.space}</p>}
                        {part.list && (
                            <ul>
                                {part.list.map((listItem) => (
                                    <li key={listItem}>{listItem}</li>
                                ))}
                            </ul>
                        )}

                        {part.img && <img src={part.img} alt={part.heading} />}
                        {part.images && part.images.map(img => <img src={img} alt={part.heading} />)}
                        {part.code && (
                            <pre lang='python'>
                                <code>
                                    {part.code}
                                </code>

                            </pre>
                        )}
                        {part.tags && <div className='tags'>{part.tags.map(tag => <span>{tag}</span>)}</div>}
                    </div>
                ))}
            </div>

            <div className="other-posts">
            </div>
        </div>
    )
}

export default Post