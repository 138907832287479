import React, { useEffect } from 'react'
import BlogPost from '../BlogPost/BlogPost'
import "../Blog.css"
const BlogList = ({ posts }) => {

    useEffect(() => {
        if (!localStorage.getItem("posts_title")) {
            const processedPosts = posts.map((post) => {
                const tags = post.parts?.find((part) => part.tags)?.tags || [];
                const title = post.title;

                return { tags, title };
            });
            localStorage.setItem("posts_title", JSON.stringify(processedPosts));
        }
    }, [posts])
    return (
        <section className="blog-list">
            {posts.map((post) => (
                <BlogPost key={post.id} {...post} />
            ))}
        </section>
    )
}

export default BlogList