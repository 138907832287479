import React from 'react';
import "./footer.css";

const Footer = () => {
  return (
    <div id='footer' className='footer-container'>
      <div className="footer-links">
        <div className="footer-column">
          <h4>Resources</h4>
          <a href="#footer">Blog</a>
          <a href="#footer" >Docs</a>
          <a href="#footer" onClick={() => window.location.replace("https://huggingface.co/QueryloopAI")}>Queryloop AI Labs</a>
        </div>
        <div className="footer-column">
          <h4>Social</h4>
          <a href="https://twitter.com">Twitter</a>
          <a href="https://github.com">GitHub</a>
          <a href="https://linkedin.com">LinkedIn</a>
          <a href="https://discord.com">Discord</a>
          <a href="https://youtube.com">YouTube</a>
        </div>
        <div className="footer-column newsletter">
          <h4>Newsletter</h4>
          <div className='newsletter-inputs'>
            <input type="email" placeholder="Your email" />
            <button>Subscribe</button>
          </div>
        </div>
      </div>
      <div className='footer'>
        <p>© 2023 Queryloop (EngineRay Inc.). All rights reserved.</p>
      </div>
    </div>
  )
}

export default Footer;
