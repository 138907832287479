import React, { useRef, useState } from 'react';
import "./Packages.css"; // Assuming your CSS is here

const PackageContainer = () => {

    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    const handleSubscribeButtonClick = () => {
        window.location.replace("https://app.queryloop-ai.com/auth/signup");
    };

    const containerRef = useRef();


    const handleMouseMove = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        setMousePosition({
            x: e.clientX - rect.left,
            y: e.clientY - rect.top,
        });
    };

    const glowStyle = {
        '--mouse-x': `${mousePosition.x}px`,
        '--mouse-y': `${mousePosition.y}px`,
    };


    return (
        <div className='pricing-page' data-aos="fade-down">
            <div
                ref={containerRef}
                id="enterpriseContainer"
                className="package-container"
                onMouseMove={handleMouseMove}
                style={glowStyle}
            >
                <div className='package_'>
                    <div className='package_header'>
                        <h2>Pro</h2>
                        {/* <img src={pro} alt='pro' /> */}
                    </div>

                    <div className='package_price'>
                        <div className='package_price_info'>
                            <p className='priceStyles'>USD 50</p>
                            <div className='per-month'>
                                <p>per user /</p>
                                <p>month</p>
                            </div>
                        </div>
                        <button onClick={handleSubscribeButtonClick}>Subscribe</button>
                    </div>
                    <br />

                    <div className='package_includes'>
                        <p className='this-inlcudes'>This includes:</p>
                        <p > <span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Retrieval optimization including the identification of the right chunking strategy, retrieval/reranking, embedding model, and LLM.</p>
                        <p ><span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Prompt optimization to automatically give the right instruction to the LLM.</p>
                        <p ><span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Metadata filtering to retrieve only the documents you want.</p>
                        <p ><span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Problem statement information extraction to land on the best parameters quickly.</p>
                        <p ><span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Access to embedding optimization</p>
                        <p ><span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Access to dual fine-tuning of retrieval, and LLM (coming soon)</p>
                        <p ><span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Access to Beta Features</p>
                    </div>
                </div>




            </div>

            <div
                id="enterpriseContainer"
                ref={containerRef}
                className="package-container glow-effect"
                onMouseMove={handleMouseMove}

            >
                <div className='package_'>
                    <div className='package_header'>
                        <h2>Enterprise</h2>
                        {/* <img src={enterprise} alt='enterprise' /> */}
                    </div>

                    <div className='package_price'>
                        <div className='package_price_info'>
                            <p className='priceStyles'>Contact us for pricing</p>

                        </div>
                        <button className='button' onClick={handleSubscribeButtonClick}>Contact us</button>
                    </div>
                    <br />
                    <div className='package_includes'>
                        <p className='this-inlcudes'>This includes:</p>
                        <p > <span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b >Everything in Pro Package plus the following:</b></p>
                        <p ><span className="checkmark">✔</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Extensive Custom Support by top LLM experts</p>
                    </div>


                </div>




            </div>



        </div>
    );
};

export default PackageContainer;
